import React from "react";
import styled from "styled-components";

import { CgRadioCheck } from "react-icons/cg";
import { MdCheckCircle } from "react-icons/md";
import { getAltClassName } from "./Utilities/Util.js";
import QuestionDetailJustification from "./QuestionDetailJustification.js";
import {
  barChartYes,
  barChartNo,
  barChartUncertain,
  barChartNotAnswered,
} from "./colors";

import "../config.js";

const QuestionDetailStyled = styled.tr`
  .answer-radio svg {
    width: 24px;
  }
`;

const QuestionDetail = ({ iRowIndex, questionAnswer, questionAnswerList }) => {
  const altClassName = getAltClassName(iRowIndex);

  return (
    <QuestionDetailStyled
      className={
        questionAnswer.answer_data.is_visible === 0
          ? "not-asked-question " + altClassName
          : altClassName
      }
    >
      {questionAnswer.answer_data.is_visible > 0 ? (
        <>
          <td
            className='question-id'
            title={
              "Q" +
              questionAnswer.tmp_question_id +
              ": " +
              questionAnswer.section_name
            }
          >
            {questionAnswer.tmp_question_id}:
          </td>

          <td
            onClick={() =>
              (window.location.href = "/#qid" + questionAnswer.question_id)
            }
            style={{ cursor: "pointer" }}
            title='Click to view in questionnaire'
          >
            {questionAnswer.question_name}
            {/* {questionAnswer.option_group_id} */}

            {global.config.chart.show_question_justification && (
              <QuestionDetailJustification
                questionAnswerData={questionAnswer}
                questionAnswerList={questionAnswerList}
              />
            )}
          </td>
          <td title='Yes' className='answer-radio'>
            {questionAnswer.answer_data.choice_id ===
            global.config.questionnaire.option_choice_id.yes ? (
              <MdCheckCircle
                style={{ color: barChartYes, fontSize: "1.0rem" }}
              />
            ) : (
              <CgRadioCheck
                style={{ color: barChartYes, fontSize: "1.0rem" }}
              />
            )}
          </td>
          <td title='No' className='answer-radio'>
            {questionAnswer.answer_data.choice_id ===
            global.config.questionnaire.option_choice_id.no ? (
              <MdCheckCircle
                style={{ color: barChartNo, fontSize: "1.0rem" }}
              />
            ) : (
              <CgRadioCheck style={{ color: barChartNo, fontSize: "1.0rem" }} />
            )}
          </td>

          {/* YesNoUncertainNa or YesNoUncertain */}
          {questionAnswer.option_group_id === 5 ||
          questionAnswer.option_group_id === 55 ? (
            <td
              title='Uncertain'
              className='answer-radio'
              style={{ textAlign: "center" }}
            >
              {questionAnswer.answer_data.choice_id ===
              global.config.questionnaire.option_choice_id.uncertain ? (
                <MdCheckCircle
                  style={{ color: barChartUncertain, fontSize: "1.0rem" }}
                />
              ) : (
                <CgRadioCheck
                  style={{ color: barChartUncertain, fontSize: "1.0rem" }}
                />
              )}
            </td>
          ) : (
            <td></td>
          )}

          {/* YesNoUncertainNa */}
          {questionAnswer.option_group_id === 5 ? (
            <td title='Not Applicable' className='answer-radio'>
              {questionAnswer.answer_data.choice_id ===
              global.config.questionnaire.option_choice_id.not_applicable ? (
                <MdCheckCircle
                  style={{ color: barChartNotAnswered, fontSize: "1.0rem" }}
                />
              ) : (
                <CgRadioCheck
                  style={{ color: barChartNotAnswered, fontSize: "1.0rem" }}
                />
              )}
            </td>
          ) : (
            <td></td>
          )}
        </>
      ) : (
        <>
          <td className='question-id'>{questionAnswer.tmp_question_id}:</td>
          <td title='Question Not Presented'>{questionAnswer.question_name}</td>
          <td
            colSpan='4'
            style={{
              whiteSpace: "nowrap",
              color: barChartNo,
              fontWeight: "bold",
            }}
          >
            Not Asked
          </td>
        </>
      )}
    </QuestionDetailStyled>
  );
};

export default QuestionDetail;
