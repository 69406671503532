import React from "react";
import "../../config";

import styled from "styled-components";

const RiverFunctionPercentageBarStyled = styled.table`
  margin-top: 0 !important;
  margin-bottom: 1px;
  font-weight: bold;

  .answer-percentage {
    text-align: center;
  }

  .yes-percentage {
    width: ${(props) => props.$percentageyes};
    background-color: ${(props) => props.theme.color.barChartYes};
    border-top: 1px solid ${(props) => props.theme.color.barChartYes};
    border-bottom: 1px solid ${(props) => props.theme.color.barChartYes};
    color: black;
  }

  .no-percentage {
    width: ${(props) => props.$percentageno};
    background-color: ${(props) => props.theme.color.barChartNo};
    border-top: 1px solid ${(props) => props.theme.color.barChartNo};
    border-bottom: 1px solid ${(props) => props.theme.color.barChartNo};
    color: white;
  }

  .uncertain-percentage {
    width: ${(props) => props.$percentageuncertain};
    background-color: ${(props) => props.theme.color.barChartUncertain};
    border-top: 1px solid ${(props) => props.theme.color.barChartUncertain};
    border-bottom: 1px solid ${(props) => props.theme.color.barChartUncertain};
    color: white;
  }

  .na-percentage {
    width: ${(props) => props.$percentagenotanswered};
    background-color: ${(props) => props.theme.color.barChartNotAnswered};
    border-top: 1px solid ${(props) => props.theme.color.barChartNotAnswered};
    border-bottom: 1px solid ${(props) => props.theme.color.barChartNotAnswered};
    color: black;
  }
`;

const RiverFunctionPercentageBar = ({ riverFunction }) => {
  return <>{GetPercentageBar(riverFunction)}</>;

  // this function is used for both "River Function" and "River Function Category"
  function GetPercentageBar(riverFunction) {
    const k =
      riverFunction.river_function_category_id === null ||
      riverFunction.river_function_category_id === undefined
        ? riverFunction.river_function_code
        : riverFunction.river_function_category_id;

    let content = [];
    if (riverFunction.answered_count === 10000) {
      content.push(
        <table key={k}>
          <tbody>
            <tr>
              <td>na</td>
            </tr>
          </tbody>
        </table>
      );
      return content;
    }

    let percentageYes = parseInt(riverFunction.yes_percentage * 100) + "%";
    let percentageNo = parseInt(riverFunction.no_percentage * 100) + "%";
    let percentageUnc = parseInt(riverFunction.unc_percentage * 100) + "%";
    let percentageNotAnswered =
      parseInt(riverFunction.not_answered_percentage * 100) + "%";

    content.push(
      <RiverFunctionPercentageBarStyled
        className='river-function-table'
        style={{ width: "100%" }}
        key={k}
        $percentageno={percentageNo}
        $percentagenotanswered={percentageNotAnswered}
        $percentageuncertain={percentageUnc}
        $percentageyes={percentageYes}
      >
        <tbody>
          <tr className='percentage-bar'>
            {/* ************  YES ************ */}
            {riverFunction.yes_percentage > 0 ? (
              <td
                className='yes-percentage answer-percentage'
                title={
                  "Yes: " +
                  percentageYes +
                  " " +
                  riverFunction.yes_percentage_desc
                }
              >
                {percentageYes}{" "}
              </td>
            ) : null}

            {/* ************  NO ************ */}
            {riverFunction.no_percentage > 0 ? (
              <td
                className='no-percentage answer-percentage'
                title={
                  "No: " + percentageNo + " " + riverFunction.no_percentage_desc
                }
              >
                {percentageNo}{" "}
              </td>
            ) : null}

            {/* ************  Uncertain ************ */}
            {riverFunction.unc_percentage > 0 ? (
              <td
                className='uncertain-percentage answer-percentage'
                title={
                  "Unc: " +
                  percentageUnc +
                  " " +
                  riverFunction.unc_percentage_desc
                }
              >
                {percentageUnc}{" "}
              </td>
            ) : null}

            {/* ************  Not Answer ************ */}
            {riverFunction.not_answered_percentage > 0 ? (
              <td
                className='na-percentage answer-percentage'
                title={
                  "Not Answered: " +
                  percentageNotAnswered +
                  " " +
                  riverFunction.not_answered_percentage_desc
                }
              >
                {percentageNotAnswered}{" "}
              </td>
            ) : null}
          </tr>
        </tbody>
      </RiverFunctionPercentageBarStyled>
    );

    return content;
  }
};

export default RiverFunctionPercentageBar;
