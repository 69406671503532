/* DEPENDENCIES */
import React from "react";
import { ThemeProvider } from "styled-components";

const theme = {
  color: {
    barChartNo: "#19537b",
    barChartNotAnswered: "#6bc5ff",
    barChartUncertain: "#4a778d",
    barChartYes: "#bdeeff",
    body: "#000000",
    primary: "#177ac8",
    primaryLink: "#0067b6",
    warning: "#de762c",
    inputs: "#7c7c7c",
    bb: "#ddf7e8",
    bbStroke: "#007833",
    cf: "#e0dcdc",
    cfStroke: "#4f4e4e",
    hd: "#ccdbe5",
    hdStroke: "#19537b",
    gm: "#fff2d9",
    gmStroke: "#9c6a07",
    lc: "#fce1ce",
    lcStroke: "#8b4513",
    wq: "#daefff",
    wqStroke: "#0067b6",
  },
  font: ["Roboto"],
  // font: ["Roboto-Regular", "Roboto-Medium", "Roboto-Bold", "Roboto-Black"],
  breakpoint: {
    xs: "325px",
    sm: "576px",
    md: "768px",
    lg: "992px",
    xl: "1200px",
    xxl: "1400px",
  },
  shadow: {
    primary: "0 2px 4px 0 rgba(0, 0, 0, 0.3)",
  },
};

const Theme = ({ children }) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default Theme;
