import ReactTooltip from "react-tooltip";
import styled from "styled-components";
import { FcInfo } from "react-icons/fc";
import "../config.js";

const ToolTipStyled = styled.span`
  svg {
    width: 24px;
    height: 24px;
  }
`;

const QuestionNameText = ({ questionData }) => {
  return <>{getQuestionNameText(questionData)}</>;
};

function getQuestionNameText(questionData) {
  const isVisible =
    questionData.answer_data.is_visible === null
      ? 1
      : questionData.answer_data.is_visible;

  let questionNameText;
  if (
    questionData.parent_id === null &&
    (isVisible === 1 || isVisible === 999)
  ) {
    questionNameText = (
      <>
        <div className='row'>
          <div className='col-11'>
            <h5>
              {questionData.section_group_txt +
                questionData.question_display_id}
              : {questionData.question_name}
              {global.config.show_question_id_for_development &&
                " -- " + questionData.question_id}
            </h5>
          </div>
          <div className='col-1' style={{ textAlign: "right" }}>
            {questionData.question_help_txt !== "" &&
              questionData.question_help_txt !== null &&
              getToolTip(
                questionData.question_id,
                questionData.question_help_txt
              )}
          </div>
        </div>
        {global.config.show_question_debug_info === true && (
          <div>
            (dependent count: {questionData.has_dependent_ind} QID:{" "}
            {questionData.question_id})
          </div>
        )}
        <p>{questionData.question_subtext}</p>
      </>
    );
  } else if (questionData.parent_id === null && isVisible === 0) {
    questionNameText = (
      <div>
        <p>
          <span className='not-required-question'>
            {questionData.section_group_txt +
              questionData.question_display_id +
              ": " +
              questionData.question_name}
          </span>
        </p>
        {global.config.show_question_debug_info && (
          <p>
            (dependent count: {questionData.has_dependent_ind} QID:{" "}
            {questionData.question_id})
          </p>
        )}
        <p>
          <i>Your answer is not required.</i>
        </p>
      </div>
    );
  } else if (
    questionData.parent_id !== null &&
    (isVisible === 1 || isVisible === 999)
  ) {
    questionNameText = (
      <>
        <h6>
          {questionData.question_name}{" "}
          {questionData.question_help_txt !== "" &&
            questionData.question_help_txt !== null &&
            getToolTip(
              questionData.question_id,
              questionData.question_help_txt
            )}
        </h6>
        {questionData.question_subtext && (
          <p>{questionData.question_subtext}</p>
        )}
        {global.config.show_question_debug_info === true && (
          <div>
            (dependent count: {questionData.has_dependent_ind} QID:{" "}
            {questionData.question_id})
          </div>
        )}

        {global.config.show_question_id_for_development &&
          " -- " + questionData.question_id}
      </>
    );
  } else {
    questionNameText = (
      <div>
        <p>
          <span className='not-required-question'>
            {questionData.question_name}
          </span>
        </p>
        <p>
          <i>Your answer is not required.</i>
        </p>
      </div>
    );
  }
  return questionNameText;
}

function getToolTip(question_id, question_help_txt) {
  //https://www.npmjs.com/package/react-tooltip
  if (question_help_txt === "") return null;

  return (
    <>
      <ToolTipStyled
        className='btn btn-link'
        data-for={"question_" + question_id + "_tooltipClass"}
        data-tip={question_help_txt}
      >
        <FcInfo />
      </ToolTipStyled>
      <ReactTooltip
        id={"question_" + question_id + "_tooltipClass"}
        className='tooltipClass'
        place='right'
        html={true}
        type='info'
      />
    </>
  );
}

export default QuestionNameText;
