import React from "react";
import styled from "styled-components";

import "../config.js";

import RiverFunctionIndicatorTable from "./Utilities/RiverFunctionIndicatorTable.js";
import PageTitle from "./PageTitle.js";
import SequentialPageNav from "./SequentialPageNav/SequentialPageNav.js";
import LinkOut from "./SVGs/LinkOut.js";
import PDFIcon from "./SVGs/PDFIcon.js";

const IntroductionSummaryStyled = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1114px;

  @media screen and (max-width: ${(props) => props.theme.breakpoint.lg}) {
    padding: 0 2rem;
  }

  .section__title {
    align-self: flex-start;
    margin-bottom: 0;
    font-size: 1.125rem;
    font-weight: 700;
  }

  ul.copy {
    list-style: disc;
    margin-left: 1.875rem;
  }

  .pdf-icon,
  .linkout-icon {
    width: 20px;
    height: auto;
    margin-top: -2px;
  }

  .figure {
    margin-bottom: 1.625rem;
    width: 100%;
    height: auto;
  }

  .fig-caption {
    font-family: ${(props) => props.theme.font[0]}, sans-serif;
    font-weight: 500;
    text-align: center;
  }

  .divider {
    margin: 0.75rem 0 2.25rem;
    height: 1px;
    width: 100%;
    background-color: #dedede;
  }

  .sequential-page-nav {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
`;

const IntroductionSummary = () => {
  document.title = global.config.title + ": " + global.config.page_title;

  return (
    <IntroductionSummaryStyled
      className='content-wrapper'
      id='Introduction'
      style={{ pageBreakBefore: "always" }}
    >
      <PageTitle title='Welcome to the Hydropower Environmental Decision Support Toolkit' />
      <p className='copy'>
        The Hydropower Environmental Decision Support (EDS) Toolkit has been
        developed by{" "}
        <a href='https://www.ornl.gov' target='_blank' rel='noreferrer'>
          Oak Ridge National Laboratory (ORNL)
          <LinkOut />
        </a>{" "}
        with the support of the{" "}
        <a href='https://www.energy.gov' target='_blank' rel='noreferrer'>
          U.S. Department of Energy
          <LinkOut />
        </a>{" "}
        <a
          href='https://www.energy.gov/eere/water/water-power-technologies-office'
          target='_blank'
          rel='noreferrer'
        >
          Water Power Technologies Office (WPTO)
          <LinkOut />
        </a>
        . This toolkit is designed to facilitate discussions among stakeholders
        involved in hydropower project planning by offering a systematic and
        transparent platform to consider potential environmental impacts.
      </p>
      <h5 className='section__title'>Key Features</h5>
      <ul className='copy'>
        <li>
          Helps in identifying environmental impacts from hydropower projects.
        </li>
        <li>
          Tailored for new projects or adding hydropower to existing non-powered
          dams, including, but not limited to, Federal Energy Regulatory
          Commission (FERC) hydropower licensing proceedings.
        </li>
        <li>
          For existing dams, the EDS Toolkit focuses only on the environmental
          impacts created by the proposed hydropower addition. The current EDS
          Toolkit is not intended to address pumped storage or conduit projects.
        </li>
      </ul>
      <h5 className='section__title'>How to Use the EDS Toolkit</h5>
      <p className='copy'>
        To use the EDS Toolkit, one should start with the Impacts module and
        work through the remaining menu items from left to right.
      </p>
      <img
        src='/images/how-to-use-eds-toolkit.png'
        alt="Depicts the proper flow of how to use the EDS toolkit. Order of modules: impact, prioritization, report, what's next"
        title='How to Use the EDS Toolkit'
        width='750'
      />
      <h5 className='section__title'>1: Impacts Module</h5>
      <p className='copy'>
        To identify potential environmental impacts from the proposed hydropower
        project, the River Function Indicator (RFI) Questionnaire within the
        Impacts module may be filled out either individually or as a group;
        however, it was designed with the expectation that individuals familiar
        with the hydropower project will answer the questions (i.e., licensees,
        environmental consultants, state and federal resource agencies, NGOs,
        and others). It will likely take you over an hour to fill out the entire
        Questionnaire. The Questionnaire will identify key functions of the
        riverine ecosystem that may be affected by a hydropower project's
        construction and/or operations.
      </p>
      <h5 className='section__title'>2: Prioritization Module</h5>
      <p className='copy'>
        Results from the RFI Questionnaire can be slimmed down to 3-5 “Key
        Topics” (i.e., RFIs flagged through ≥50% “Yes” responses) and “Uncertain
        Topics” (i.e., RFIs flagged through ≥50% “Uncertain” responses) through
        use of the Prioritization module.
      </p>
      <h5 className='section__title'>3. Report Module</h5>
      <p className='copy'>
        View detailed report for River Function Indicator Questionnaire (RFIQ).
      </p>
      <h5 className='section__title'>4. What's Next? Module</h5>
      <p className='copy'>
        Examples of previously implemented environmental studies and
        mitigations, as well as information about relevant permitting and
        regulatory information, will be provided for each identified topic in
        the What's Next? module. The information from the Questionnaire may be
        used as a resource during the study plan development phase of a FERC
        licensing proceeding, the EDS Toolkit does not recommend specific study
        methodologies or suggest any specific protection, mitigation, or
        enhancement measures.
      </p>
      <h5 className='section__title'>
        The Six River Function Indicator (RFI) Categories
      </h5>
      <p className='copy'>
        The EDS Toolkit was developed to provide interested parties with a
        systematic and transparent process for identifying riverine ecosystem
        functions that may be affected by a hydropower project's construction
        and/or operations and to identify relevant datasets and data gaps. Each
        RFI represents a group of environmental metrics used to measure a common
        characteristic of an ecosystem. The tool's questions address{" "}
        <a
          href='https://lowimpacthydro.org/wp-content/uploads/2020/07/A_Checklist_of_River_Function_Indicators_for_hydropower_ecological_assessment-2019.pdf'
          target='_blank'
          rel='noreferrer'
        >
          a checklist of 42 RFIs &nbsp;
          <PDFIcon />
        </a>{" "}
        organized into six categories described below in Table 1: Biology &
        Biota, Connectivity & Fragmentation, Geomorphology, Land Cover, Water
        Quality, and Water Quantity. The RFIs are based on the results of an
        extensive{" "}
        <a
          href='https://lowimpacthydro.org/wp-content/uploads/2020/07/Review-of_Environmental-Metrics-Esther-S.-Parish-et-al-2019.pdf'
          target='_blank'
          rel='noreferrer'
        >
          literature review of environmental metrics &nbsp;
          <PDFIcon />
        </a>
        which integrated the viewpoints of multiple interested parties and the
        scientific research community.
      </p>
      <div className='copy group-in-print'>
        <p>
          <strong>
            Table 1. The six River Function Indicator (RFI) categories and their
            importance for understanding hydropower impacts
          </strong>
        </p>
      </div>
      <RiverFunctionIndicatorTable></RiverFunctionIndicatorTable>
      <span className='divider'></span>
      <div className='copy'>
        <p>
          Version 6 of the EDS Toolkit provides a new What’s Next? module with
          connections to DOE's Regulatory and Permitting Information Desktop
          (RAPID) Toolkit for Hydropower developed by the National Renewable
          Energy Laboratory (NREL). Once users have identified key RFIs to
          explore further, they may explore information about relevant
          regulations and permit requirements pertaining to those key
          environmental indicators in the RAPID Toolkit. The connections between
          the EDS Toolkit and{" "}
          <a
            href='https://openei.org/wiki/RAPID/Hydropower'
            target='_blank'
            rel='noreferrer'
          >
            the RAPID Toolkit <LinkOut />
          </a>{" "}
          are illustrated below.
        </p>
        <img
          id='eds-rapid-diagram'
          src='/images/EDS-RAPID-merge-process-flow.png'
          alt='EDS RAPID merge process flow diagram'
          title='Figure 2'
        />
      </div>
      <SequentialPageNav next='/Impacts' />
    </IntroductionSummaryStyled>
  );
};

export default IntroductionSummary;
