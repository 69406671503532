import React, { useState, useEffect } from "react";
import { BsArrowsCollapse, BsArrowsExpand } from "react-icons/bs";
import { CgMoreO, CgMoreVerticalO } from "react-icons/cg";
import RiverFunctionChartCategoryDetail from "./RiverFunctionChartCategoryDetail.js";
import RiverFunctionPercentageBar from "./Utilities/RiverFunctionPercentageBar.js";
import "../config";
import QuestionDetail from "./QuestionDetail.js";
import {
  barChartYes,
  barChartNo,
  barChartUncertain,
  barChartNotAnswered,
} from "./colors";

import styled from "styled-components";

const RiverFunctionCategoryStyled = styled.tr`
  color: black;
  margin-bottom: 1px;

  .expand-collapse,
  .rfc-header,
  .show-hide {
    background-color: ${(props) =>
      props.theme.color[props.$rfccode]} !important;
    border: 1px solid ${(props) => props.theme.color[props.$rfccode + "Stroke"]};
  }
  .expand-collapse {
    border-right: none;
  }

  .rfc-header {
    border-left: none;
    border-right: none;
    margin-top: 20px;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    color: black;
  }

  .show-hide {
    border-left: none;
  }

  .bar-graph {
    margin-top: 0 !important;
    border-bottom: none !important;
  }
`;

const RiverFunctionChartCategory = ({
  rfc,
  riverFunctionSummary,
  answerJson,
  isShowQuestionsUnderCategory,
  riverFunctionSortOption,
  isShowDetail,
  isShowRiverFunctions,
}) => {
  const [isShowDetailOption, setIsShowDetailOption] = useState(isShowDetail);
  const [
    isShowRiverFunctionCategoryQuestions,
    setIsShowRiverFunctionCategoryQuestions,
  ] = useState(isShowQuestionsUnderCategory);

  isShowRiverFunctions =
    isShowRiverFunctions === null ? true : isShowRiverFunctions;

  useEffect(() => {
    setIsShowDetailOption(isShowDetail);
  }, [isShowDetail]);

  useEffect(() => {
    setIsShowRiverFunctionCategoryQuestions(isShowQuestionsUnderCategory);
  }, [isShowQuestionsUnderCategory]);

  const riverFunctionsForThisCategory = riverFunctionSummary.filter(function (
    rf
  ) {
    return rf.river_function_category_id === rfc.river_function_category_id;
  });

  const questionListUnderThisRiverFunctionCategory = answerJson.filter(
    function (answerData) {
      return this.indexOf(answerData.question_id) >= 0;
    },
    rfc.question_list
  ); // riverFunction.question_list: [1, 2, 3, 4, 5, etc..]

  const headerClassName =
    "river-function-category rfc-code-" + rfc.river_function_category_code;

  const sortRiverFunction = (riverFunctions, sortBy) => {
    // sort by percentage.  First: Yes Percentage Desc, Second: Uncertain Percentage Desc
    if (sortBy === "percentage") {
      riverFunctions.sort(function (a, b) {
        if (b.yes_percentage === a.yes_percentage)
          return b.unc_percentage - a.unc_percentage;

        return b.yes_percentage - a.yes_percentage;
      });
    } else if (sortBy === "id") {
      riverFunctions.sort(function (a, b) {
        //return a.question_id - b.question_id;
        return a.order_index - b.order_index;
      });
    }

    return riverFunctions;
  };

  return (
    <>
      <RiverFunctionCategoryStyled
        $rfccode={rfc.river_function_category_code.toLowerCase()}
      >
        <td
          className={headerClassName + " expand-collapse"}
          onClick={(e) => setIsShowDetailOption(!isShowDetailOption)}
          title='Expand / Collapse details'
        >
          {isShowDetailOption ? <BsArrowsCollapse /> : <BsArrowsExpand />}
        </td>
        <td
          className={`${headerClassName} rfc-header `}
          title={global.config.chart.test_mode ? rfc.count_desc : ""}
          style={{ cursor: "pointer" }}
          onClick={() =>
            setIsShowRiverFunctionCategoryQuestions(
              !isShowRiverFunctionCategoryQuestions
            )
          }
        >
          {rfc.river_function_category_name}
        </td>
        <td
          className={headerClassName + " show-hide"}
          title='Show/Hide Questions/Answers Lists under this River Function Category'
          onClick={() =>
            setIsShowRiverFunctionCategoryQuestions(
              !isShowRiverFunctionCategoryQuestions
            )
          }
          style={{ cursor: "pointer", paddingRight: "5px" }}
        >
          {!isShowRiverFunctionCategoryQuestions ? (
            rfc.not_answered_count > 0 ? (
              <CgMoreO color='red' title='Some questions Have Not Answered' />
            ) : (
              <CgMoreO />
            )
          ) : rfc.not_answered_count > 0 ? (
            <CgMoreVerticalO
              color='red'
              title='Some questions Have Not Answered'
            />
          ) : (
            <CgMoreVerticalO />
          )}
        </td>
        <td
          style={{ verticalAlign: "bottom" }}
          className='river-function-category bar-graph'
          title={rfc.count_desc}
        >
          <RiverFunctionPercentageBar
            riverFunction={rfc}
            key={rfc.river_function_category_id}
          />
        </td>
      </RiverFunctionCategoryStyled>
      {isShowRiverFunctionCategoryQuestions && (
        <RiverFunctionCategoryStyled>
          <td colSpan='4' align='right'>
            <table className='river-function-item-question-table'>
              <thead>
                <tr>
                  <td colSpan='6'>
                    <p>{rfc.definition_txt} - A</p>
                    <p>{rfc.impact_txt} - A</p>
                  </td>
                </tr>
                <tr>
                  <td
                    colSpan='2'
                    rowSpan='2'
                    style={{ paddingRight: "20px", padding: "2px" }}
                  >
                    {"Total: " +
                      rfc.question_list.length +
                      ' questions under "' +
                      rfc.river_function_category_name +
                      '"'}
                  </td>
                  <td
                    title='Yes'
                    style={{
                      backgroundColor: barChartYes,
                      textAlign: "center",
                      color: "black",
                      width: "22px",
                    }}
                  >
                    Yes
                  </td>
                  <td
                    title='No'
                    style={{
                      backgroundColor: barChartNo,
                      textAlign: "center",
                      color: "white",
                      width: "22px",
                    }}
                  >
                    No
                  </td>
                  <td
                    title='Uncertain'
                    style={{
                      backgroundColor: barChartUncertain,
                      textAlign: "center",
                      color: "white",
                      width: "22px",
                    }}
                  >
                    Unc
                  </td>
                  <td
                    title='Not Applicable'
                    style={{
                      backgroundColor: barChartNotAnswered,
                      textAlign: "center",
                      color: "black",
                      width: "22px",
                    }}
                  >
                    NA
                  </td>
                </tr>

                <tr>
                  <td
                    title='Yes'
                    style={{
                      backgroundColor: barChartYes,
                      textAlign: "center",
                    }}
                  >
                    {rfc.yes_count}
                  </td>
                  <td
                    title='No'
                    style={{
                      backgroundColor: barChartNo,
                      textAlign: "center",
                      color: "white",
                    }}
                  >
                    {rfc.no_count}
                  </td>
                  <td
                    title='Uncertain'
                    style={{
                      backgroundColor: barChartUncertain,
                      textAlign: "center",
                      color: "white",
                    }}
                  >
                    {rfc.unc_count}
                  </td>
                  <td
                    title='Not Applicable'
                    style={{
                      backgroundColor: barChartNotAnswered,
                      textAlign: "center",
                      color: "black",
                    }}
                  >
                    {rfc.na_count}
                  </td>
                </tr>
              </thead>
              <tbody>
                {questionListUnderThisRiverFunctionCategory.map(
                  (questionAnswer, i) => (
                    <QuestionDetail
                      key={i}
                      iRowIndex={i}
                      questionAnswer={questionAnswer}
                      questionAnswerList={answerJson}
                    />
                  )
                )}
              </tbody>
            </table>
          </td>
        </RiverFunctionCategoryStyled>
      )}

      {isShowRiverFunctions &&
        sortRiverFunction(
          riverFunctionsForThisCategory,
          riverFunctionSortOption
        ).map((riverFunction, i) => (
          <RiverFunctionChartCategoryDetail
            key={i}
            rfc={rfc}
            riverFunction={riverFunction}
            answerJson={answerJson}
            isShowDetail={isShowDetailOption}
          />
        ))}
      <tr>
        <td colSpan='4'>&nbsp;</td>
      </tr>
    </>
  );
};

export default RiverFunctionChartCategory;
