import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { CgMoreO, CgMoreVerticalO } from "react-icons/cg";
import QuestionDetail from "./QuestionDetail.js";
import {
  barChartYes,
  barChartNo,
  barChartUncertain,
  barChartNotAnswered,
} from "./colors";

import RiverFunctionPercentageBar from "./Utilities/RiverFunctionPercentageBar.js";

const RiverFunctionChartCategoryDetailStyled = styled.tr`
  margin-bottom: 1px;
  cursor: pointer;

  .rfc-header,
  .show-hide {
    background-color: ${(props) =>
      props.$isshowdetail
        ? props.theme.color[props.$rfccode.toLowerCase()]
        : "transparent"};
    border: ${(props) =>
      props.$isshowdetail
        ? `1px solid ${
            props.theme.color[props.$rfccode.toLowerCase() + "Stroke"]
          }`
        : "none"};
  }
  .expand-collapse {
    border-right: none;
  }

  .rfc-header {
    border-right: none;
    margin-top: 20px;
    font-weight: ${(props) => (props.$isshowdetail ? "bold" : "normal")};
    text-align: right;
  }

  .show-hide {
    border-left: none;
  }
`;

const RiverFunctionChartCategoryDetail = ({
  rfc,
  riverFunction,
  answerJson,
  isShowDetail,
}) => {
  const [isShowDetailOption, setIsShowDetailOption] = useState(isShowDetail);

  useEffect(() => {
    setIsShowDetailOption(isShowDetail);
  }, [isShowDetail]);

  // this is the list of questions for this River Function
  const questionListUnderThisRiverFunction = answerJson.filter(function (
    answerData
  ) {
    return this.indexOf(answerData.question_id) >= 0;
  },
  riverFunction.question_list); // riverFunction.question_list: [1, 2, 3, 4, 5, etc..]

  const categoryNameClassName = isShowDetailOption
    ? "rfc-selected rfc-" + rfc.river_function_category_code
    : "";

  return (
    <>
      <RiverFunctionChartCategoryDetailStyled
        $rfccode={rfc.river_function_category_code.toLowerCase()}
        onClick={(e) => setIsShowDetailOption(!isShowDetailOption)}
        $isshowdetail={isShowDetailOption ? true : false}
      >
        <td className='expand-collapse'></td>
        {/* this is River Function */}
        <td
          className={"river-function-item rfc-header " + categoryNameClassName}
        >
          <span title={riverFunction.river_function_desc_txt}>
            {riverFunction.river_function_name}&nbsp;(
            {riverFunction.river_function_code})
          </span>
        </td>
        <td
          className={categoryNameClassName + " show-hide"}
          title='Show/Hide Questions/Answers Lists under this River Function'
        >
          {/* This is an icon.  Depending on the NotAnsweredCount and Expanded/Collapsed, it shows different icon (style) */}
          {!isShowDetailOption ? (
            riverFunction.not_answered_count > 0 ? (
              <CgMoreO color='red' title='Some questions Have Not Answered' />
            ) : (
              <CgMoreO />
            )
          ) : riverFunction.not_answered_count > 0 ? (
            <CgMoreVerticalO
              color='red'
              title='Some questions Have Not Answered'
            />
          ) : (
            <CgMoreVerticalO />
          )}
        </td>
        <td>
          <RiverFunctionPercentageBar
            key={rfc.river_function_category_id}
            riverFunction={riverFunction}
          ></RiverFunctionPercentageBar>
        </td>
      </RiverFunctionChartCategoryDetailStyled>
      {/* This section is to display questions under River Function */}
      {isShowDetailOption && (
        <RiverFunctionChartCategoryDetailStyled>
          <td colSpan='4' align='right'>
            <table
              className='river-function-item-question-table'
              style={{ marginBottom: "20px", marginLeft: "20%" }}
            >
              <thead>
                <tr>
                  <td
                    colSpan='2'
                    rowSpan='2'
                    style={{ paddingRight: "20px", padding: "2px" }}
                    // className={'rfc-code-' + rfc.river_function_category_code}
                  >
                    Total {questionListUnderThisRiverFunction.length} questions
                    under "{riverFunction.river_function_name} (
                    {riverFunction.river_function_code})" <br></br>(
                    {riverFunction.river_function_desc_txt})
                  </td>
                  <td
                    title='Yes'
                    style={{
                      backgroundColor: barChartYes,
                      textAlign: "center",
                      color: "black",
                      width: "22px",
                    }}
                  >
                    Yes
                  </td>
                  <td
                    title='No'
                    style={{
                      backgroundColor: barChartNo,
                      textAlign: "center",
                      color: "white",
                      width: "22px",
                    }}
                  >
                    No
                  </td>
                  <td
                    title='Uncertain'
                    style={{
                      backgroundColor: barChartUncertain,
                      textAlign: "center",
                      color: "white",
                      width: "22px",
                    }}
                  >
                    Unc
                  </td>
                  <td
                    title='Not Applicable'
                    style={{
                      backgroundColor: barChartNotAnswered,
                      textAlign: "center",
                      color: "black",
                      width: "22px",
                    }}
                  >
                    NA
                  </td>
                </tr>
                <tr>
                  <td
                    title='Yes'
                    style={{
                      backgroundColor: barChartYes,
                      textAlign: "center",
                    }}
                  >
                    {riverFunction.yes_count}
                  </td>
                  <td
                    title='No'
                    style={{
                      backgroundColor: barChartNo,
                      textAlign: "center",
                      color: "white",
                    }}
                  >
                    {riverFunction.no_count}
                  </td>
                  <td
                    title='Uncertain'
                    style={{
                      backgroundColor: barChartUncertain,
                      textAlign: "center",
                      color: "white",
                    }}
                  >
                    {riverFunction.unc_count}
                  </td>
                  <td
                    title='Not Applicable'
                    style={{
                      backgroundColor: barChartNotAnswered,
                      textAlign: "center",
                      color: "black",
                    }}
                  >
                    {riverFunction.na_count}
                  </td>
                </tr>
              </thead>
              <tbody>
                {questionListUnderThisRiverFunction.map((questionAnswer, i) => (
                  <QuestionDetail
                    key={i}
                    iRowIndex={i}
                    questionAnswer={questionAnswer}
                    questionAnswerList={answerJson}
                  />
                ))}
              </tbody>
            </table>
          </td>
        </RiverFunctionChartCategoryDetailStyled>
      )}
    </>
  );
};

export default RiverFunctionChartCategoryDetail;
