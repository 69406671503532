import React, { useEffect, useState } from "react";
import styled from "styled-components";

import jsonData from "../Data/RiverFunctionCategoryWithQuestionArray.json";
import Collapsible from "../Collapsible";
import RFICategory from "../RFICategory";

const RiverFunctionIndicatorsStyled = styled.section`
  width: 100%;
  height: auto;
`;

export default function RiverFunctionIndicators() {
  const [rfiData, setRFIData] = useState();
  useEffect(() => {
    const sortedData = jsonData.sort(function (a, b) {
      return a.river_function_category_id - b.river_function_category_id;
    });

    setRFIData(sortedData);
  }, []);
  return (
    <RiverFunctionIndicatorsStyled>
      {rfiData && rfiData.length > 0
        ? rfiData.map((rfi, index) => (
            <Collapsible
              bgColor={rfi.bgColor}
              borderColor={rfi.borderColor}
              centerTitle={true}
              content={rfi.rfiData}
              itemIndex={index}
              key={rfi.river_function_category_id}
              title={`${rfi.river_function_category_name} (${rfi.river_function_category_code})`}
            >
              <RFICategory
                bgColor={rfi.bgColor}
                borderColor={rfi.borderColor}
                categoryId={rfi.river_function_category_id}
                definition={rfi.definition_txt}
                impact={rfi.impact_txt}
              />
            </Collapsible>
          ))
        : null}
    </RiverFunctionIndicatorsStyled>
  );
}
