import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { FaSpinner } from "react-icons/fa";

const TabButtonStyled = styled.button`
  margin-right: 1px;
  padding: ${(props) =>
    props.$selectedTab === props.$index ? "8px 8px 4px" : "4px 8px"};
  background-color: ${(props) => props.theme.color[props.$rfccode]} !important;
  border: 1px solid ${(props) => props.theme.color[props.$rfccode + "Stroke"]};
  border-radius: 4px 4px 0 0;
  color: black;
`;

const Table = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [tabDataReady, setTabDataReady] = useState(false);
  const [filteredAndSortedTabData, setFilteredAndSortedTabData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchColumn, setSearchColumn] = useState("all");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let topicCategory = global.config.prioritization.KeyTopicCategory;
    let uncertainTopicCategory =
      global.config.prioritization.UncertainTopicCategory;

    //Grab all the key topics
    const getRiverFunctionPrioritization = (topicCategoryName) => {
      //debugger;
      const priorityTopicCategoryText = localStorage.getItem(
        "Prioritization_" + topicCategoryName
      );

      let newColumns = null;

      if (
        priorityTopicCategoryText !== null &&
        priorityTopicCategoryText !== "" &&
        priorityTopicCategoryText !== "null"
      ) {
        try {
          newColumns = JSON.parse(priorityTopicCategoryText);
          //newColumns = setColumnNameAndDescription(topicCategoryName, newColumns);
        } catch (e) {
          console.log("error:", e);
        }
      }

      return newColumns;
    };
    //getting all certain and uncertain topics
    const topicsForThisCategory = getRiverFunctionPrioritization(topicCategory);
    const uncertaintopicsForThisCategory = getRiverFunctionPrioritization(
      uncertainTopicCategory
    );
    const retainedTopics =
      topicsForThisCategory === null ? [] : topicsForThisCategory.Retain.items;
    const uncertainRetainedTopics =
      uncertaintopicsForThisCategory === null
        ? []
        : uncertaintopicsForThisCategory.Retain.items;

    //grabs indicators from retainedTopics and uncertainRetainedTopics and put them in a new array
    function getIndicators(arr1, arr2) {
      const indicators = [];

      // Filter and push river_function_code from arr1
      arr1.forEach((item) => {
        if (item.river_function_code) {
          indicators.push(item.river_function_code);
        }
      });

      // Filter and push river_function_code from arr2
      arr2.forEach((item) => {
        if (item.river_function_code) {
          indicators.push(item.river_function_code);
        }
      });

      return indicators;
    }

    const fetchData = async () => {
      setLoading(true);
      const indicators = getIndicators(retainedTopics, uncertainRetainedTopics);
      let states = [""];

      // Getting the states the user selected for question B5, id 778
      const answerFromLocalStorage = localStorage.getItem(
        "AnswerToQuestion_778"
      );

      if (answerFromLocalStorage) {
        const optionChoices = JSON.parse(answerFromLocalStorage).option_choices;

        if (optionChoices && optionChoices.length > 0) {
          states = optionChoices.map((choice) => choice.option_choice_name);
        } else {
          console.error("No option choices found in AnswerToQuestion_778");
        }
      } else {
        console.error("AnswerToQuestion_778 not found in localStorage");
      }

      const url = "/api/getstudydata/";
      await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ indicators: indicators, states: states }),
      })
        .then((r) => r.json())
        .then((d) => {
          // Initialize filteredTabData with an empty array
          let filteredTabData = [];
          if (d && Array.isArray(d)) {
            filteredTabData = d;
            //adding in note of data not being available for all non-BB indicators
            filteredTabData.forEach((item) => {
              if (item.data.length === 0) {
                item.data = [
                  {
                    region:
                      "Note: Data is only available for BB, CF, and WQ indicators. Other categories of indicators coming soon...",
                  },
                ];
              }
            });

            // Sort the tab data to match the order of the user's selected indicators
            let filteredAndSortedTabData = indicators.map((label) =>
              filteredTabData.find((item) => item.label === label)
            );
            setFilteredAndSortedTabData(filteredAndSortedTabData);
            setTabDataReady(true);
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          // Handle error condition here if needed
        })
        .finally(() => {
          setLoading(false); // Set loading to false once the fetch is done
        });
    };
    fetchData();
  }, []);

  const handleTabClick = (index) => {
    setSelectedTab(index);
  };

  // START Controls for sorting the table alphabetically by column
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortedColumn, setSortedColumn] = useState(null);

  const handleSort = (column) => {
    if (sortedColumn === column) {
      setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
    } else {
      setSortedColumn(column);
      setSortOrder("asc");
    }
  };

  const sortedData = filteredAndSortedTabData[selectedTab]?.data || [];

  const sortedAndFilteredData = sortedData.sort((a, b) => {
    const keyA = a[sortedColumn] || "";
    const keyB = b[sortedColumn] || "";

    if (sortOrder === "asc") {
      return keyA.localeCompare(keyB);
    } else {
      return keyB.localeCompare(keyA);
    }
  });

  const getSortIndicator = (column) => {
    if (column === sortedColumn) {
      return sortOrder === "asc" ? "↑" : "↓";
    }
    return "↕"; // Default indicators for other columns
  };
  //END Controls for sorting the table alphabetically by column

  const handleSearch = (event) => {
    const searchTerm = event.target.value;
    setSearchTerm(searchTerm);
  };

  const handleDropdownChange = (event) => {
    const column = event.target.value;
    setSearchColumn(column);
  };

  return (
    <div>
      <div className="tab-buttons">
        {tabDataReady &&
          filteredAndSortedTabData.map((tab, index) => (
            <TabButtonStyled
              $rfccode={tab.label.slice(0, 2).toLowerCase()}
              $selectedTab={selectedTab}
              $index={index}
              key={index}
              className={`tab-button ${selectedTab === index ? "active" : ""}`}
              onClick={() => handleTabClick(index)}
            >
              {tab.label}
            </TabButtonStyled>
          ))}
      </div>
      <div style={{ marginTop: "10px" }}>
        <input
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={handleSearch}
        />
        <select
          value={searchColumn}
          onChange={handleDropdownChange}
          style={{ marginLeft: "10px", marginRight: "5px" }}
        >
          <option value="all">All</option>
          <option value="region">FERC Regions</option>
          <option value="state">States</option>
          <option value="waterbodyNames">Waterbodies</option>
          <option value="facilityNames">Projects</option>
          <option value="capacity">Project Capacity</option>
          <option value="environmentalStudies">Environmental Studies</option>
          <option value="ferc">FERC Dockets</option>
          <option value="year">Year</option>
        </select>
      </div>
      <div
        className="table-container"
        style={{ overflowX: "auto", maxHeight: "900px" }}
      >
        {loading ? (
          <div style={{ textAlign: "center", padding: "20px" }}>
            <FaSpinner className="spinner" style={{ fontSize: "2em" }} />
          </div>
        ) : (
          <table className="table">
            <thead
              style={{
                position: "sticky",
                top: "0",
                backgroundColor: "lightgray",
              }}
            >
              <tr>
                <th style={{ whiteSpace: "nowrap", minWidth: "80px" }}>
                  <div
                    onClick={() => handleSort("region")}
                    style={{ cursor: "pointer" }}
                  >
                    FERC Regions {getSortIndicator("region")}
                  </div>
                </th>
                <th style={{ whiteSpace: "nowrap", minWidth: "80px" }}>
                  <div
                    onClick={() => handleSort("state")}
                    style={{ cursor: "pointer" }}
                  >
                    States {getSortIndicator("state")}
                  </div>
                </th>
                <th style={{ whiteSpace: "nowrap", minWidth: "120px" }}>
                  <div
                    onClick={() => handleSort("waterbodyNames")}
                    style={{ cursor: "pointer" }}
                  >
                    Waterbodies {getSortIndicator("waterbodyNames")}
                  </div>
                </th>
                <th style={{ whiteSpace: "nowrap", minWidth: "120px" }}>
                  <div
                    onClick={() => handleSort("facilityNames")}
                    style={{ cursor: "pointer" }}
                  >
                    Projects {getSortIndicator("facilityNames")}
                  </div>
                </th>
                <th style={{ whiteSpace: "nowrap", minWidth: "100px" }}>
                  <div
                    onClick={() => handleSort("capacity")}
                    style={{ cursor: "pointer" }}
                  >
                    Project Capacity (KW) {getSortIndicator("capacity")}
                  </div>
                </th>
                <th style={{ whiteSpace: "nowrap", minWidth: "120px" }}>
                  <div
                    onClick={() => handleSort("environmentalStudies")}
                    style={{ cursor: "pointer" }}
                  >
                    Environmental Studies
                    {getSortIndicator("environmentalStudies")}
                  </div>
                </th>
                <th style={{ whiteSpace: "nowrap", minWidth: "80px" }}>
                  <div
                    onClick={() => handleSort("ferc")}
                    style={{ cursor: "pointer" }}
                  >
                    FERC Dockets {getSortIndicator("ferc")}
                  </div>
                </th>
                <th style={{ whiteSpace: "nowrap", minWidth: "80px" }}>
                  <div
                    onClick={() => handleSort("year")}
                    style={{ cursor: "pointer" }}
                  >
                    Year {getSortIndicator("year")}
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {(() => {
                if (sortedAndFilteredData.length === 0) {
                  return (
                    <tr>
                      <td colSpan="8">
                        No data available. Please be sure to answer all
                        questions in the Questionnaire and select key topics in
                        the 'Prioritization' page.
                      </td>
                    </tr>
                  );
                } else {
                  const filteredData = sortedAndFilteredData.filter((data) => {
                    const searchTermLower = searchTerm.toLowerCase();
                    if (searchColumn === "all") {
                      return (
                        (data.region &&
                          data.region
                            .toLowerCase()
                            .includes(searchTermLower)) ||
                        (data.state &&
                          data.state.toLowerCase().includes(searchTermLower)) ||
                        (data.waterbodyNames &&
                          data.waterbodyNames
                            .toLowerCase()
                            .includes(searchTermLower)) ||
                        (data.facilityNames &&
                          data.facilityNames
                            .toLowerCase()
                            .includes(searchTermLower)) ||
                        (data.capacity &&
                          data.capacity
                            .toLowerCase()
                            .includes(searchTermLower)) ||
                        (data.environmentalStudies &&
                          data.environmentalStudies
                            .toLowerCase()
                            .includes(searchTermLower)) ||
                        (data.ferc &&
                          data.ferc.toLowerCase().includes(searchTermLower)) ||
                        (data.year &&
                          data.year.toLowerCase().includes(searchTermLower))
                      );
                    } else {
                      return (
                        data[searchColumn] &&
                        data[searchColumn]
                          .toLowerCase()
                          .includes(searchTermLower)
                      );
                    }
                  });

                  if (filteredData.length === 0) {
                    return (
                      <tr>
                        <td colSpan="8">
                          No data available. Please refine your search terms.
                        </td>
                      </tr>
                    );
                  }

                  return filteredData.map((data, dataIndex) => (
                    <tr
                      key={dataIndex}
                      style={{
                        backgroundColor:
                          dataIndex % 2 === 1 ? "lightgray" : "#fff",
                      }}
                    >
                      <td>{data.region || ""}</td>
                      <td>{data.state || ""}</td>
                      <td>{data.waterbodyNames || ""}</td>
                      <td>{data.facilityNames || ""}</td>
                      <td>{data.capacity || ""}</td>
                      <td>{data.environmentalStudies || ""}</td>
                      <td>
                        {data.ferc
                          ? data.ferc.split(",").map((docket, index) => (
                              <span key={index}>
                                <a
                                  href={`https://hydropowerelibrary.pnnl.gov/Projects/${docket.trim()}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {docket.trim()}
                                </a>
                                {index < data.ferc.split(",").length - 1 &&
                                  ", "}
                              </span>
                            ))
                          : ""}
                      </td>
                      <td>{data.year || ""}</td>
                    </tr>
                  ));
                }
              })()}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default Table;
