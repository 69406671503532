import React from "react";
import QuestionsListData from "./Data/QuestionsData.json";
import RiverFunctionWithQuestionArray from "./Data/RiverFunctionWithQuestionArray.json";
import {
  getRevisedQuestionWithAnswers,
  getRiverFunctionSummary,
} from "./Utilities/Util.js";
import QuestionDetail from "./QuestionDetail.js";
import RiverFunctionPercentageBar from "./Utilities/RiverFunctionPercentageBar.js";
import {
  barChartYes,
  barChartNo,
  barChartUncertain,
  barChartNotAnswered,
} from "./colors";

const RiverFunctionDetail = ({ riverFunctionCode }) => {
  const answerJson = getRevisedQuestionWithAnswers(QuestionsListData);
  const riverFunctionSummary = getRiverFunctionSummary(
    RiverFunctionWithQuestionArray,
    answerJson
  );
  // const riverFunctionCategorySummary = getRiverFunctionCategorySummary(RiverFunctionCategoryWithQuestionArray, answerJson);

  const riverFunctionList = riverFunctionSummary.filter((rf) => {
    return (
      rf.river_function_code === riverFunctionCode ||
      riverFunctionCode === null ||
      riverFunctionCode === ""
    );
  });

  const riverFunction =
    riverFunctionList.length === 1 ? riverFunctionList[0] : [];

  // this is the list of questions for this River Function
  const questionListUnderThisRiverFunction = answerJson.filter(function (
    answerData
  ) {
    return this.indexOf(answerData.question_id) >= 0;
  },
  riverFunction.question_list); // riverFunction.question_list: [1, 2, 3, 4, 5, etc..]

  return (
    <div>
      <div
        style={{
          backgroundColor: riverFunction.bgColor,
          border: `1px solid ${riverFunction.borderColor}`,
          margin: "5px",
          padding: "5px",
          textAlign: "center",
          color: "black",
          borderRadius: "1.0rem",
        }}
      >
        {riverFunction.river_function_code} :{" "}
        {riverFunction.river_function_name} (
        {riverFunction.river_function_category_name})
      </div>
      <div style={{ margin: "10px" }}>
        <RiverFunctionPercentageBar
          key={riverFunction.river_function_category_id}
          riverFunction={riverFunction}
        />
      </div>

      <table
        className='river-function-item-question-table'
        style={{ fontSize: "0.8rem", margin: "10px" }}
      >
        <thead>
          <tr>
            <td
              colSpan='2'
              rowSpan='2'
              style={{ paddingRight: "20px", padding: "2px" }}
              // className={'rfc-code-' + rfc.river_function_category_code}
            >
              Total {questionListUnderThisRiverFunction.length} questions under
              "{riverFunction.river_function_name} (
              {riverFunction.river_function_code})" <br></br>(
              {riverFunction.river_function_desc_txt})
            </td>
            <td
              title='Yes'
              className
              style={{
                backgroundColor: barChartYes,
                textAlign: "center",
                color: "black",
              }}
            >
              Yes
            </td>
            <td
              title='No'
              style={{
                backgroundColor: barChartNo,
                textAlign: "center",
                color: "white",
              }}
            >
              No
            </td>
            <td
              title='Uncertain'
              style={{
                backgroundColor: barChartUncertain,
                textAlign: "center",
                color: "white",
              }}
            >
              Unc
            </td>
            <td
              title='Not Applicable'
              style={{
                backgroundColor: barChartNotAnswered,
                textAlign: "center",
                color: "black",
              }}
            >
              NA
            </td>
          </tr>
          <tr>
            <td
              title='Yes'
              style={{ backgroundColor: barChartYes, textAlign: "center" }}
            >
              {riverFunction.yes_count}
            </td>
            <td
              title='No'
              style={{
                backgroundColor: barChartNo,
                textAlign: "center",
                color: "white",
              }}
            >
              {riverFunction.no_count}
            </td>
            <td
              title='Uncertain'
              style={{
                backgroundColor: barChartUncertain,
                textAlign: "center",
                color: "white",
              }}
            >
              {riverFunction.unc_count}
            </td>
            <td
              title='Not Applicable'
              style={{
                backgroundColor: barChartNotAnswered,
                textAlign: "center",
                color: "black",
              }}
            >
              {riverFunction.na_count}
            </td>
          </tr>
        </thead>
        <tbody>
          {questionListUnderThisRiverFunction.map((questionAnswer, i) => (
            <QuestionDetail
              key={i}
              iRowIndex={i}
              questionAnswer={questionAnswer}
              questionAnswerList={answerJson}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default RiverFunctionDetail;
